import React from "react";
import { Routes, Route } from 'react-router-dom';

import Layout from './Components/Layout'
import Home from './Components/Home'
import About from './Components/About'
import Contact from './Components/Contact'

import "./App.css"

function App()  {

    return (
       <div className="app">
            <Routes>                
                <Route path="/" element={<Layout />}>
                    <Route index element={ <Home /> } />
                    <Route path="about" element={ <About /> } />   
                    <Route path="contact" element={ <Contact /> } />               
                </Route>
            </Routes>       
        </div>
    );
}

export default App;