import React from "react";
import { Outlet } from 'react-router-dom';
import LayoutNavBar from "./LayoutNavBar"

import "./style/layout.css"

export default function Layout() {
    return (
      <>
        <LayoutNavBar />

        
        
        <div className="layout-body">
          <Outlet />
        </div>
      </>      
    );
}