import React from "react";
// import { Link } from 'react-router-dom';

function Home() {
    return (
      <div >
        <h2>Home View</h2>
        <p>Lorem ipsum dolor sit amet, consectetur adip.</p>   
      </div>
    );
}

export default Home;


