import React from "react";

function About() {
    return (
      <div >
        <h2>About View</h2>
        <p>Lorem ipsum dolor sit amet, consectetur adip.</p> 
      </div>
    );
}

export default About;
