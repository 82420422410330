import React from "react";
import { Link } from 'react-router-dom';

import "./style/layout-navbar.css"

export default function LayoutNavBar() {
  return (

      <nav className="layout-nav-bar">

        <div className="nav-home">
          <Link to="/"><p>Scott Gilhooly</p></Link>
        </div>

        <ul className="nav-links">

          <li className="nav-item">
            <Link to="/about"><p>About</p></Link>
          </li>

          <li className="nav-item">
            <Link to="/contact"><p>Contact</p></Link>
          </li>

        </ul>

      </nav>

  );
}
