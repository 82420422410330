import React from "react";

export default function Contact() {
    return (
      <div >
        <h2>Contact</h2>

        <ul>
          <li><p>Instagram</p></li>
          <li><p>Facebook</p></li>
          <li><p>Twitter</p></li>
          <li><p>Flickr</p></li>
          <li><p>Email</p></li>
        </ul>
        
      </div>
    );
}
